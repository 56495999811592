<template>
    <div>
      <div class="wrap">
        <div class="pc-footer-symbol"><!--PC Ver.--></div>
        <div class="mo-footer-symbol"><!--Mo Ver.--></div>
        <div class="container">
          <div class="navi">
            <router-link :to="{path: `/about/intro`}">
              <div class="item">About</div>
            </router-link>
            <router-link :to="{path: `/market/list`, query: {...$route.query}}">
              <div class="item">Market</div>
            </router-link>
            <router-link :to="{path: `/membership`, query: {...$route.query}}">
              <div class="item">Membership</div>
            </router-link>
            <router-link :to="{path: `/cs/faq`, query: {...$route.query}}">
              <div class="item">FAQ</div>
            </router-link>
            <router-link :to="{path: `/about/terms`, query: {key: 'terms'}}">
              <div class="item">이용약관</div>
            </router-link>
            <router-link :to="{path: `/about/terms`, query: {key: 'privacy'} }">
              <div class="item">개인정보취급방침</div>
            </router-link>
          </div>
        </div>
        <div class="container-colm">
          <div class="info">
            <div class="item">
              대표자 : 이차형
            </div>
            <div class="item">
              개인정보책임관리자 : 서동수
            </div>
            <div class="item">
              사업자등록번호 : 850-81-02474
            </div>
            <div class="item">
              <a href="tel:02-415-9887">
                Tel : 02)415-9887
              </a>
            </div>
            <!--
            <div class="item">
              서울특별시 송파구 올림픽로8길 32
            </div>
            -->
            <div class="item">
              지원정보: {{version}} {{timestamp}}
            </div>
          </div>
          <ul class="action-list">
            <li><a href="mailto:dongsu@winex.ai?subject=사용문의" target="_blank">사용문의</a></li>
            <li><a href="mailto:dongsu@winex.ai?subject=민터 등록문의" target="_blank">민터 등록문의</a></li>
            <li><a href="mailto:dongsu@winex.ai?subject=채용문의" target="_blank">채용문의</a></li>
          </ul>
          <div class="copyright">
            <span>2022 WINEX. Copyright All rights reserved.</span>
            <ul>
              <li>
                <router-link :to="{path: `/about/terms`, query: {key: 'terms'}}">
                    이용약관
                </router-link>
              </li>
              <li>
                <router-link :to="{path: `/about/terms`, query: {key: 'privacy'} }">
                  개인정보 취급방침
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
      name: "DefaultFooter",
      data() {
        const date = new Date(document.documentElement.dataset.buildTimestampUtc);
        return {
          // eslint-disable-next-line
          version: GIT_DESCRIBE?.raw || "",
          timestamp: this.format(date)
        };
      },
      methods: {
        format(time) {
          const timezone = -time.getTimezoneOffset();
          return `${time.getYear()}`.padStart(2, "0").slice(-2)
            + `${time.getMonth() + 1}`.padStart(2, "0")
            + `${time.getDate()}`.padStart(2, "0")
            + "T"
            + `${time.getHours()}`.padStart(2, "0")
            + `${time.getMinutes()}`.padStart(2, "0")
            + `${time.getSeconds()}`.padStart(2, "0")
            + (timezone > 0 ? '+' : '-')
            + `${Number.parseInt(timezone / 60)}`.padStart(2, "0")
            + `${timezone % 60}`.padStart(2, "0");
        }
      }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/default-footer.scss";
</style>
